import React, { memo, useCallback, useMemo } from 'react';
import {
    DispatcherMissionSlotDto,
    KeyGetGetAllMissions,
    useMutationDeleteDeallocateBus,
    useMutationDeleteRemoveMission,
    useMutationPatchUpdateNumberOfTours,
    useMutationPostAllocateBus,
    useMutationPostDuplicateToBus,
} from '../../../../../../backend/gen';
import { MenuItem } from '@mui/material';
import { useQueryClient } from 'react-query';
import { MissionStripMenuContentsToursMenuItem } from './MissionStripMenuContentsToursMenuItem';
import { PopoverMenu } from '../../../../../../components/navigation/PopoverMenu/PopoverMenu';
import { CustomMenuStyles } from '../../../../../../components/CustomMenu/CustomMenu.style';
import { FormattedMessage } from 'react-intl';
import { toast } from 'react-toastify';
import { errorToastConfig } from '../../../../../../utils/constants';
import { useMissionForm } from '../../../../../../contexts/MissionFormContext';

export const MissionStripMenuContents = memo(function MissionStripMenuContents({
    buses,
    close,
    mission,
    isMissionTerminated,
    isUnallocated,
}: {
    buses: string[];
    close: () => void;
    mission?: DispatcherMissionSlotDto;
    isMissionTerminated: boolean;
    isUnallocated: boolean;
}) {
    const queryClient = useQueryClient();
    const { openDialog } = useMissionForm();

    const flightNbTours = useMemo(() => (isUnallocated ? null : mission.nbTours), [isUnallocated, mission.nbTours]);

    // Route to allocate mission to bus
    const { mutateAsync: allocateBus } = useMutationPostAllocateBus();
    const onClickAllocateBus = useCallback(
        (bus: string) => {
            allocateBus({ mission: mission.missionId, bus: bus })
                .then(() => {
                    close();
                    queryClient.invalidateQueries(KeyGetGetAllMissions);
                })
                .catch(() => {
                    close();
                    toast(<FormattedMessage id={'bus.error.allocateBus'} values={{ bus: bus }} />, errorToastConfig);
                });
        },
        [allocateBus, close, mission, queryClient],
    );

    // Route to delete manually created mission
    const { mutateAsync: deleteMission } = useMutationDeleteRemoveMission();
    const onClickDeleteMission = useCallback(() => {
        deleteMission({ mission: mission.missionId })
            .then(() => {
                close();
                queryClient.invalidateQueries(KeyGetGetAllMissions);
            })
            .catch(() => {
                close();
                toast(<FormattedMessage id={'bus.error.deleteManual'} />, errorToastConfig);
            });
    }, [close, deleteMission, mission, queryClient]);

    // Route to reallocate mission to different bus
    const { mutateAsync: reallocateBus } = useMutationPostAllocateBus();
    const onClickReallocateBus = useCallback(
        (bus: string) => {
            reallocateBus({ bus: bus, mission: mission.missionId })
                .then(() => {
                    close();
                    queryClient.invalidateQueries(KeyGetGetAllMissions);
                })
                .catch(() => {
                    close();
                    toast(<FormattedMessage id={'bus.error.reallocateBus'} values={{ bus: bus }} />, errorToastConfig);
                });
        },
        [close, mission, queryClient, reallocateBus],
    );

    // Route to unallocate mission from bus
    const { mutateAsync: unallocateMission } = useMutationDeleteDeallocateBus();
    const onClickUnallocateMission = useCallback(() => {
        unallocateMission({ mission: mission.missionId })
            .then(() => {
                close();
                queryClient.invalidateQueries(KeyGetGetAllMissions);
            })
            .catch(() => {
                close();
                toast(<FormattedMessage id={'bus.error.unallocateBus'} />, errorToastConfig);
            });
    }, [close, mission, queryClient, unallocateMission]);

    // Route to update mission's number of tours
    const { mutateAsync: updateMissionNbTours } = useMutationPatchUpdateNumberOfTours();
    const onConfirmUpdateMissionNbTours = useCallback(
        (tours: number) => {
            updateMissionNbTours({ tours: tours, mission: mission.missionId })
                .then(() => {
                    close();
                    queryClient.invalidateQueries(KeyGetGetAllMissions);
                })
                .catch(() => {
                    close();
                    toast(<FormattedMessage id={'bus.error.updateNbTours'} />, errorToastConfig);
                });
        },
        [close, mission, queryClient, updateMissionNbTours],
    );

    // Route to duplicate mission to bus
    const { mutateAsync: duplicateMissionToBus } = useMutationPostDuplicateToBus();
    const onClickDuplicateMissionToBus = useCallback(
        (bus: string) => {
            duplicateMissionToBus({ bus: bus, mission: mission.missionId })
                .then(() => {
                    close();
                    queryClient.invalidateQueries(KeyGetGetAllMissions);
                })
                .catch(() => {
                    close();
                    toast(
                        <FormattedMessage id={'bus.error.duplicateMissionToBus'} values={{ bus: bus }} />,
                        errorToastConfig,
                    );
                });
        },
        [close, duplicateMissionToBus, mission, queryClient],
    );

    const menuItems = useMemo(() => {
        const list = [];

        list.push(
            <MenuItem
                onClick={event => {
                    close();
                    openDialog(
                        mission?.type === 'OTHER' || mission?.type === 'RECURRENT'
                            ? 'otherMissionForm'
                            : 'flightMissionForm',
                        mission,
                    );
                }}
            >
                <FormattedMessage id={'stripFlight.menu.editMission'} />
            </MenuItem>,
        );

        // List all buses & allocate onClick
        if (!flightNbTours)
            buses.map((bus, index) =>
                list.push(
                    <MenuItem onClick={() => onClickAllocateBus(bus)} key={index}>
                        <FormattedMessage id={'stripFlight.menu.allocate'} values={{ bus: bus }} />
                    </MenuItem>,
                ),
            );

        if (!flightNbTours && !isMissionTerminated) {
            list.push(
                <MenuItem onClick={onClickDeleteMission}>
                    <FormattedMessage id={'stripFlight.menu.deleteManual'} />
                </MenuItem>,
            );
        }

        // List all buses in new popover & duplicate onClick
        if (flightNbTours)
            list.push(
                <PopoverMenu
                    renderButton={({ onClick }) => (
                        <MenuItem onClick={onClick}>
                            <FormattedMessage id={'stripFlight.menu.duplicate'} />
                        </MenuItem>
                    )}
                    menuProps={{
                        anchorOrigin: { vertical: 'top', horizontal: 'right' },
                        transformOrigin: { vertical: 'top', horizontal: 'left' },
                    }}
                    menuStyles={CustomMenuStyles}
                >
                    {buses.map((bus, index) => (
                        <MenuItem onClick={() => onClickDuplicateMissionToBus(bus)} key={index}>
                            {bus}
                        </MenuItem>
                    ))}
                </PopoverMenu>,
            );

        // List all buses in new popover & reallocate onClick
        if (flightNbTours && !isMissionTerminated)
            list.push(
                <PopoverMenu
                    renderButton={({ onClick }) => (
                        <MenuItem onClick={onClick}>
                            <FormattedMessage id={'stripFlight.menu.reallocate'} />
                        </MenuItem>
                    )}
                    menuProps={{
                        anchorOrigin: { vertical: 'top', horizontal: 'right' },
                        transformOrigin: { vertical: 'top', horizontal: 'left' },
                    }}
                    menuStyles={CustomMenuStyles}
                >
                    {buses.map((bus, index) => (
                        <MenuItem onClick={() => onClickReallocateBus(bus)} key={index}>
                            {bus}
                        </MenuItem>
                    ))}
                </PopoverMenu>,
            );

        // Show nb of tours & allow + or - with a confirm button to call update route
        if (flightNbTours && mission.type !== 'OTHER' && mission.type !== 'RECURRENT')
            list.push(
                <MissionStripMenuContentsToursMenuItem
                    tours={flightNbTours}
                    onConfirm={onConfirmUpdateMissionNbTours}
                    isMissionTerminated={isMissionTerminated}
                />,
            );

        // Unallocate onClick
        if (flightNbTours && !isMissionTerminated)
            list.push(
                <MenuItem onClick={onClickUnallocateMission}>
                    <FormattedMessage id={'stripFlight.menu.unallocate'} />
                </MenuItem>,
            );

        return list;
    }, [
        flightNbTours,
        buses,
        isMissionTerminated,
        mission,
        onConfirmUpdateMissionNbTours,
        onClickUnallocateMission,
        close,
        openDialog,
        onClickAllocateBus,
        onClickDeleteMission,
        onClickDuplicateMissionToBus,
        onClickReallocateBus,
    ]);

    return (
        <>
            {menuItems.map((item, i) => (
                <React.Fragment key={i}>{item}</React.Fragment>
            ))}
        </>
    );
});

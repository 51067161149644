import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import {
    StyledAirportName,
    StyledAirportNameContainer,
    StyledArrivalIcon,
    StyledBusDisplayContentDepContainer,
    StyledCenterContainer,
    StyledDashedLine,
    StyledDepartureIcon,
    StyledFlightDelayed,
    StyledFlightInfoContainer,
    StyledFlightNumber,
    StyledLeftSideContainer,
    StyledRightSideContainer,
    StyledTimeAndIcon,
    StyledTimeAtDestinationInfoText,
} from './BusDisplayContentDep.style';
import { FlightInfoDto } from '../../../../backend/gen';
import {
    busDisplayTranslations,
    checkIfAirlineLogoExists,
    cycleObject,
    DepartureInfoMode,
    isFullDisplayCycleComplete,
    LanguageMode,
} from '../busDisplay.utils';
import { formatInTimeZone } from 'date-fns-tz';
import Marquee from 'react-fast-marquee';
import { useBoolean } from '../../../../hooks/useBoolean';
import { FormattedTime } from '../../../../components/time/FormattedTime';

interface BusDisplayContentDepProps {
    flightInfo: FlightInfoDto;
    onCycleComplete?: () => void;
}

export const BusDisplayContentDep = memo(function BusDisplayContentDep({
    flightInfo,
    onCycleComplete,
}: BusDisplayContentDepProps) {
    const [currentLanguage, setCurrentLanguage] = useState<LanguageMode>(LanguageMode.English);
    const [isVideoPending, setIsVideoPending] = useState(false);
    const [infoToShow, setInfoToShow] = useState<DepartureInfoMode>(DepartureInfoMode.DEFAULT);
    const [cycleCount, setCycleCount] = useState(1);

    const airlineLogoExists = useBoolean(false);

    const formattedAirportName = useMemo(() => {
        return flightInfo.ades[currentLanguage]
            ? flightInfo.ades[currentLanguage].charAt(0).toUpperCase() +
                  flightInfo.ades[currentLanguage].slice(1).toLowerCase()
            : '-';
    }, [flightInfo.ades, currentLanguage]);

    const airlineLogoPath = useMemo(() => {
        return airlineLogoExists.value && flightInfo.airline?.icao
            ? `images/airlines/${flightInfo.airline.icao.toLowerCase()}.png`
            : '';
    }, [airlineLogoExists.value, flightInfo.airline?.icao]);

    const handleCycleCompletion = useCallback(() => {
        if (onCycleComplete && !isVideoPending) {
            setIsVideoPending(true);
            onCycleComplete();
            // Pause cycling if there is a video as component will unmount and reset state later
            // This prevents cycling from continuing during the transition to video
            if (!flightInfo?.videoUri) {
                setInfoToShow(DepartureInfoMode.DEFAULT);
                setCurrentLanguage(LanguageMode.English);
                setCycleCount(1);
            }
            setIsVideoPending(false);
        }
    }, [onCycleComplete, isVideoPending, flightInfo?.videoUri]);

    const languageKeys = useMemo(() => Object.keys(LanguageMode).filter(key => isNaN(Number(key))), []);
    // Always skip VIDEO mode from normal cycling
    const keysToSkip = useMemo(() => [DepartureInfoMode.VIDEO], []);
    const infoKeys = useMemo(
        () =>
            Object.keys(DepartureInfoMode).filter(
                key => isNaN(Number(key)) && !keysToSkip.includes(DepartureInfoMode[key]),
            ),
        [keysToSkip],
    );

    // Handle language cycling for non-delayed flights
    useEffect(() => {
        if (flightInfo.delayed) {
            return;
        }

        const lastLanguageIndex = languageKeys.length - 1;
        const isLastLanguage = currentLanguage === LanguageMode[languageKeys[lastLanguageIndex]];

        const timer = setInterval(() => {
            if (isLastLanguage && !isVideoPending && onCycleComplete) {
                handleCycleCompletion();
            } else {
                setCurrentLanguage(cycleObject({ obj: LanguageMode, current: currentLanguage }));
            }
        }, 10000);

        return () => clearInterval(timer);
    }, [currentLanguage, flightInfo.delayed, handleCycleCompletion, isVideoPending, languageKeys, onCycleComplete]);

    // Handle info and language cycling for delayed flights
    useEffect(() => {
        if (!flightInfo.delayed) {
            return;
        }

        const timer = setInterval(() => {
            const shouldShowVideo = isFullDisplayCycleComplete(currentLanguage, infoToShow, languageKeys, infoKeys);

            if (shouldShowVideo && !isVideoPending && onCycleComplete) {
                handleCycleCompletion();
            } else {
                setInfoToShow(
                    cycleObject({
                        obj: DepartureInfoMode,
                        current: infoToShow,
                        skipKeys: keysToSkip,
                    }),
                );

                if (cycleCount === infoKeys.length) {
                    setCurrentLanguage(cycleObject({ obj: LanguageMode, current: currentLanguage }));
                    setCycleCount(1);
                } else {
                    setCycleCount(prev => prev + 1);
                }
            }
        }, 5000);

        return () => clearInterval(timer);
    }, [
        currentLanguage,
        cycleCount,
        flightInfo.delayed,
        infoToShow,
        handleCycleCompletion,
        isVideoPending,
        languageKeys,
        infoKeys,
        keysToSkip,
        onCycleComplete,
    ]);

    useEffect(() => {
        const airlineIcao = flightInfo.airline?.icao?.toLowerCase();
        if (!airlineIcao) {
            airlineLogoExists.setFalse();
            return;
        }

        checkIfAirlineLogoExists(airlineIcao, airlineLogoExists.setTrue, airlineLogoExists.setFalse);
    }, [airlineLogoExists, flightInfo.airline?.icao]);

    return (
        <StyledBusDisplayContentDepContainer>
            <StyledLeftSideContainer>
                <StyledTimeAndIcon>
                    <span>
                        <FormattedTime>{flightInfo.sobt}</FormattedTime>
                    </span>
                    <StyledDepartureIcon />
                    <StyledDashedLine />
                </StyledTimeAndIcon>
                <StyledFlightInfoContainer>
                    {infoToShow === DepartureInfoMode.DELAYED ? (
                        <StyledFlightDelayed>
                            {busDisplayTranslations.departureFlightDelayed[currentLanguage] + ' '}
                            <FormattedTime>{flightInfo.eobt}</FormattedTime>
                        </StyledFlightDelayed>
                    ) : (
                        <StyledFlightNumber>{flightInfo.flightNumber}</StyledFlightNumber>
                    )}
                </StyledFlightInfoContainer>
            </StyledLeftSideContainer>

            <StyledCenterContainer>
                {airlineLogoExists.value && <img src={airlineLogoPath} alt="airline-logo" />}
                <StyledAirportNameContainer>
                    {formattedAirportName.length >= 10 ? (
                        <Marquee gradient={false} speed={50} style={{ overflowY: 'hidden' }}>
                            <StyledAirportName>{formattedAirportName}</StyledAirportName>
                        </Marquee>
                    ) : (
                        formattedAirportName
                    )}
                </StyledAirportNameContainer>
            </StyledCenterContainer>

            <StyledRightSideContainer>
                <StyledTimeAndIcon>
                    <StyledDashedLine />
                    <StyledArrivalIcon />
                    <span> {formatInTimeZone(flightInfo.sibt, flightInfo.adesTimeZone, 'HH:mm')}</span>
                </StyledTimeAndIcon>
                <StyledTimeAtDestinationInfoText>
                    {busDisplayTranslations.departureTimeAtDestination[currentLanguage]}
                </StyledTimeAtDestinationInfoText>
            </StyledRightSideContainer>
        </StyledBusDisplayContentDepContainer>
    );
});

/*
 * Copyright Innov'ATM all rights reserved.
 * This software is the property of Innov'ATM and may not be used in any manner except under a
 * license agreement signed with Innov'ATM.
 */

import React, { memo, useEffect, useRef } from 'react';
import { styled } from '@mui/material';

interface IBusDisplayVideoProps {
    videoUrl: string;
    onVideoEnd: () => void;
}

export const BusDisplayVideo = memo(function BusDisplayVideo({ videoUrl, onVideoEnd }: IBusDisplayVideoProps) {
    const videoRef = useRef<HTMLVideoElement>(null);

    useEffect(() => {
        const videoElement = videoRef.current;
        if (!videoElement) return;

        const handleVideoEnd = () => {
            onVideoEnd();
        };

        videoElement.addEventListener('ended', handleVideoEnd);

        videoElement.load();
        videoElement.play().catch(error => {
            console.error('Error playing video:', error);
        });

        return () => {
            videoElement.removeEventListener('ended', handleVideoEnd);
        };
    }, [onVideoEnd]);

    return (
        <StyledVideoContainer>
            <StyledVideo ref={videoRef} autoPlay muted>
                <source src={videoUrl} />
            </StyledVideo>
        </StyledVideoContainer>
    );
});

const StyledVideoContainer = styled('div')`
    width: 100%;
    height: 520px;
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const StyledVideo = styled('video')`
    width: 100%;
    height: 100%;
    object-fit: contain;
`;

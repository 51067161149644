import React, { memo, useEffect, useMemo, useState } from 'react';
import {
    StyledAirlineBox,
    StyledAirportBox,
    StyledBusDisplayContentArrContainer,
    StyledLeftSideContainer,
    StyledRightSideContainer,
} from './BusDisplayContentArr.style';
import { FlightInfoDto } from '../../../../backend/gen';
import {
    ArrivalInfoMode,
    checkIfAirlineLogoExists,
    cycleObject,
    generateArrivalInformation,
    isFullDisplayCycleComplete,
    LanguageMode,
} from '../busDisplay.utils';
import { useBoolean } from '../../../../hooks/useBoolean';

interface BusDisplayContentArrProps {
    flightInfo: FlightInfoDto;
    onCycleComplete?: () => void;
}

export const BusDisplayContentArr = memo(function BusDisplayContentArr({
    flightInfo,
    onCycleComplete,
}: BusDisplayContentArrProps) {
    const [currentLanguage, setCurrentLanguage] = useState<LanguageMode>(LanguageMode.English);
    const [infoToShow, setInfoToShow] = useState<ArrivalInfoMode>(ArrivalInfoMode.DEFAULT);
    const [cycleCount, setCycleCount] = useState(1);
    const [isVideoPending, setIsVideoPending] = useState(false);
    const airlineLogoExists = useBoolean(false);

    const airlineLogoPath = useMemo(() => {
        return airlineLogoExists.value && flightInfo.airline?.icao
            ? `images/airlines/${flightInfo.airline.icao.toLowerCase()}.png`
            : '';
    }, [airlineLogoExists.value, flightInfo.airline?.icao]);

    const arrivalInformation = useMemo(() => {
        return generateArrivalInformation({
            language: currentLanguage,
            belt: flightInfo.belt,
            oversizedBelt: infoToShow === ArrivalInfoMode.OVERSIZED && flightInfo.oversizedBelt,
            controlled: infoToShow === ArrivalInfoMode.CONTROLLED && flightInfo.controlled,
        });
    }, [currentLanguage, flightInfo.belt, flightInfo.oversizedBelt, flightInfo.controlled, infoToShow]);

    const languageKeys = useMemo(() => Object.keys(LanguageMode).filter(key => isNaN(Number(key))), []);
    // Always skip VIDEO mode from normal cycling
    const keysToSkip = useMemo(() => {
        const skipKeys = [ArrivalInfoMode.VIDEO];

        if (!flightInfo.controlled && flightInfo.oversizedBelt === null) {
            skipKeys.push(ArrivalInfoMode.CONTROLLED, ArrivalInfoMode.OVERSIZED);
        } else if (!flightInfo.controlled) {
            skipKeys.push(ArrivalInfoMode.CONTROLLED);
        } else if (flightInfo.oversizedBelt === null) {
            skipKeys.push(ArrivalInfoMode.OVERSIZED);
        }
        return skipKeys;
    }, [flightInfo.controlled, flightInfo.oversizedBelt]);
    const infoKeys = useMemo(
        () =>
            Object.keys(ArrivalInfoMode).filter(
                key => isNaN(Number(key)) && !keysToSkip.includes(ArrivalInfoMode[key]),
            ),
        [keysToSkip],
    );

    useEffect(() => {
        const timer = setInterval(() => {
            const isCycleComplete = isFullDisplayCycleComplete(currentLanguage, infoToShow, languageKeys, infoKeys);

            if (isCycleComplete && !isVideoPending && onCycleComplete) {
                setIsVideoPending(true);
                onCycleComplete();
                // Pause cycling if there is a video as component will unmount and reset state later
                // This prevents cycling from continuing during the transition to video
                if (!flightInfo?.videoUri) {
                    setInfoToShow(ArrivalInfoMode.DEFAULT);
                    setCurrentLanguage(LanguageMode.English);
                }
                setIsVideoPending(false);
            } else {
                setInfoToShow(
                    cycleObject({
                        obj: ArrivalInfoMode,
                        current: infoToShow,
                        skipKeys: keysToSkip,
                    }),
                );

                if (cycleCount === infoKeys.length) {
                    setCurrentLanguage(cycleObject({ obj: LanguageMode, current: currentLanguage }));
                    setCycleCount(1);
                } else {
                    setCycleCount(prev => prev + 1);
                }
            }
        }, 5000);

        return () => clearInterval(timer);
    }, [
        currentLanguage,
        cycleCount,
        infoToShow,
        keysToSkip,
        isVideoPending,
        languageKeys,
        infoKeys,
        onCycleComplete,
        flightInfo?.videoUri,
    ]);

    useEffect(() => {
        const airlineIcao = flightInfo.airline?.icao?.toLowerCase();
        if (!airlineIcao) {
            airlineLogoExists.setFalse();
            return;
        }

        checkIfAirlineLogoExists(airlineIcao, airlineLogoExists.setTrue, airlineLogoExists.setFalse);
    }, [airlineLogoExists, flightInfo.airline?.icao]);

    return (
        <StyledBusDisplayContentArrContainer>
            <StyledLeftSideContainer>
                <StyledAirlineBox>
                    {airlineLogoExists.value && <img src={airlineLogoPath} alt="airline-logo" />}
                    <span>{flightInfo.flightNumber}</span>
                </StyledAirlineBox>
                <StyledAirportBox>{flightInfo.adep[currentLanguage]}</StyledAirportBox>
            </StyledLeftSideContainer>
            <StyledRightSideContainer>{arrivalInformation}</StyledRightSideContainer>
        </StyledBusDisplayContentArrContainer>
    );
});

import React, { memo, useCallback, useEffect, useState } from 'react';
import { FlightFlow, useQueryGetGetFlightInfo } from '../../../backend/gen';
import {
    StyledBusDisplayContainer,
    StyledBusDisplayContentContainer,
    StyledBusDisplayFlightInfo,
    StyledNoActiveFlight,
    StyledVideoWrapper,
} from './BusDisplay.style';
import { FormattedMessage } from 'react-intl';
import { BusDisplayHeader } from './BusDisplayHeader/BusDisplayHeader';
import { BusDisplayFooter } from './BusDisplayFooter/BusDisplayFooter';
import { BusDisplayContentArr } from './BusDisplayContentArrival/BusDisplayContentArr';
import { BusDisplayContentDep } from './BusDisplayContentDeparture/BusDisplayContentDep';
import { BusDisplayVideo } from './BusDisplayVideo/BusDisplayVideo';

const queryString = require('query-string');

export const BusDisplay = memo(function BusDisplay() {
    const token = queryString.parse(window.location.search).token;
    const [showVideo, setShowVideo] = useState(false);
    const [isVideoMounted, setIsVideoMounted] = useState(false);
    const [isInfoMounted, setIsInfoMounted] = useState(true);
    const [isTransitioning, setIsTransitioning] = useState(false);
    const [opacity, setOpacity] = useState(1);

    const { data, status } = useQueryGetGetFlightInfo(
        { token: token },
        {
            refetchInterval: 4000,
        },
    );

    useEffect(() => {
        if (isTransitioning) return;

        if (showVideo && !isVideoMounted) {
            // Transition to video
            setIsTransitioning(true);
            setOpacity(0);

            // After fade out completes
            setTimeout(() => {
                setIsInfoMounted(false);
                setIsVideoMounted(true);

                // Trigger fade in
                setTimeout(() => {
                    setOpacity(1);
                    setIsTransitioning(false);
                }, 50);
            }, 1000);
        } else if (!showVideo && !isInfoMounted) {
            // Transition to flight info
            setIsTransitioning(true);
            setOpacity(0);

            // After fade out completes
            setTimeout(() => {
                setIsVideoMounted(false);
                setIsInfoMounted(true);

                // Trigger fade in
                setTimeout(() => {
                    setOpacity(1);
                    setIsTransitioning(false);
                }, 50);
            }, 1000);
        }
    }, [showVideo, isVideoMounted, isInfoMounted, isTransitioning]);

    const handleVideoEnd = useCallback(() => {
        setShowVideo(false);
    }, []);

    const handleShowVideo = useCallback(() => {
        if (data?.flow && data?.videoUri && !isTransitioning) {
            setShowVideo(true);
        }
    }, [data?.flow, data?.videoUri, isTransitioning]);

    if (!token || status === 'error') return <div>Invalid token</div>;

    return (
        <StyledBusDisplayContainer>
            {isInfoMounted && (
                <StyledBusDisplayFlightInfo style={{ opacity }}>
                    <BusDisplayHeader />
                    <StyledBusDisplayContentContainer>
                        {!data?.flow ? (
                            <StyledNoActiveFlight>
                                <FormattedMessage id={'busDisplay.noActiveFlight'} />
                            </StyledNoActiveFlight>
                        ) : data.flow === FlightFlow.ARR ? (
                            <BusDisplayContentArr flightInfo={data} onCycleComplete={handleShowVideo} />
                        ) : (
                            <BusDisplayContentDep flightInfo={data} onCycleComplete={handleShowVideo} />
                        )}
                    </StyledBusDisplayContentContainer>
                    <BusDisplayFooter flow={data?.flow} />
                </StyledBusDisplayFlightInfo>
            )}

            {isVideoMounted && data?.videoUri && (
                <StyledVideoWrapper style={{ opacity }}>
                    <BusDisplayVideo videoUrl={data.videoUri} onVideoEnd={handleVideoEnd} />
                </StyledVideoWrapper>
            )}
        </StyledBusDisplayContainer>
    );
});

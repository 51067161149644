import { Box, styled } from '@mui/material';

export const StyledBusDisplayContainer = styled(Box)`
    width: 1920px;
    height: 700px;
    position: relative;
    font-family: 'Helvetica Neue', sans-serif;
    background: white;
`;

export const StyledBusDisplayFlightInfo = styled('div')`
    opacity: 1;
    transition: opacity 1s ease-in-out;
    position: absolute;
    width: 100%;
`;

export const StyledBusDisplayContentContainer = styled(Box)`
    height: 300px;
    background-color: #1d569f;
`;

export const StyledNoActiveFlight = styled(Box)`
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 150px;
`;

export const StyledVideoWrapper = styled('div')`
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 1;
    transition: opacity 1s ease-in-out;
`;
